import AsNewIcon from "../assets/Icn-AsNew.svg";
import BrandNewIcon from "../assets/Icn-BrandNew.svg";
import PrelovedIcon from "../assets/Icn-Preloved.svg";
import RepairedIcon from "../assets/Icn-Repaired.svg";

export const DARK = "dark";
export const LIGHT = "light";

export const LEFT = "left";
export const RIGHT = "right";
export const TOP = "top";
export const BOTTOM = "bottom";

export const IMAGE_OVERLAY_Z_INDEX = 300;
export const SIDE_MENU_Z_INDEX = 200;
export const SIDE_MENU_CLOSE_Z_INDEX = 150;
export const NAV_Z_INDEX = 100;
export const FILTER_BAR_INDEX = 80;

export const SHARING_IMAGE_URL = "/images/reskinned-social-1200x630.jpg";

export const CONDITION_FILTER_TYPE = "condition";
export const GENDER_FILTER_TYPE = "gender";
export const AGE_GROUP_FILTER_TYPE = "age_group";
export const BRAND_FILTER_TYPE = "brand";
export const REPAIRED_FILTER_TYPE = "repaired";

export const FILL = "fill";
export const RESPONSIVE = "responsive";
export const FIXED = "fixed";

export const CHOOSE = "choose";
export const PACKAGE = "package";
export const DROP = "drop";
export const VALIDATE = "validate";
export const VOUCHER = "voucher";
export const CODE = "code";

export const MENSWEAR = "menswear";
export const WOMENSWEAR = "womenswear";
export const BOYS = "boys";
export const GIRLS = "girls";
export const BABY = "baby";
export const KIDS = "kids";
export const LATEST_DROP = "latest-drop";

export const UNISEX = "unisex";

export const ACTIVE = "active";

export const CONDITION_MAP = {
  pre_loved: {
    icon: PrelovedIcon,
    label: "Pre-loved",
    tooltipText:
      "Clothing that has been worn before but is in good condition, with limited signs of wear.",
  },
  as_new: {
    icon: AsNewIcon,
    label: "As new",
    tooltipText: "The item has been worn before but shows no sign of wear.",
  },
  as_new_defects: {
    icon: AsNewIcon,
    label: "As new",
    tooltipText: "The item has been worn before but shows no sign of wear.",
  },
  as_new_no_defects: {
    icon: AsNewIcon,
    label: "As new",
    tooltipText: "The item has been worn before but shows no sign of wear.",
  },
  brand_new: {
    icon: BrandNewIcon,
    label: "Brand new",
    tooltipText: "The item is brand new and has not been worn.",
  },
  repaired: {
    icon: RepairedIcon,
    label: "Repaired",
    tooltipText:
      "Has had a small repair such as the button replaced or minor stitching. Check item repair notes for details.",
  },
};

export const SORT_OPTIONS = [
  {
    label: "Featured",
    key: "default",
  },
  {
    label: "Latest",
    key: "-created",
  },
  {
    label: "Oldest",
    key: "created",
  },
  {
    label: "Price (low)",
    key: "price",
  },
  {
    label: "Price (high)",
    key: "-price",
  },
  {
    label: "Alphabetical",
    key: "title",
  },
];

export const RESKINNED_PROMO_ITEM = {
  name: "Reskinned",
  slug: "reskinned",
  shopVisibility: "inactive",
  takebackUrl: "https://takeback.reskinned.clothing/reskinned",
  brandsPromoImage: {
    height: 350,
    width: 350,
    url: "/images/website-hp-promo_350x350@2x.jpg",
  },
};
